import React from "react";
import MediaQuery from "react-responsive";

const Image = ({ src, srcMobile, alt, className, style }) => {
  if (!srcMobile)
    return <img src={src} alt={alt} className={className} style={style} />;
  return [
    <MediaQuery key="one" query="(min-width: 768px)">
      <img src={src} alt={alt} className={className} style={style} />
    </MediaQuery>,
    <MediaQuery key="two" query="(max-width: 767px)">
      <img src={srcMobile} alt={alt} className={className} style={style} />
    </MediaQuery>,
  ];
};

export default Image;
