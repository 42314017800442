import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import UnoSection from "../components/uno-section";
import Image from "../components/image";



import socialFB from "../images/reserve/social-fb.png";
import socialIG from "../images/reserve/social-ig.png";
import socialTW from "../images/reserve/social-tw.png";


const ThankYouWrapper = styled.div`
  background: #fff;
  padding: 30px;
  width: 100%;

  .socialIconsContainer {
    height: 50px;
    padding-top: 20px;
    padding-bottom: 80px;
    width:100%
    display: block;
  }

`;



const ThankYouPage = () => (
  <Layout currentPage="thank-you">
    <UnoSection
      verticalCenter
      minHeight="100vh"
      maxWidth="800px"
      bottomPadding
      topPadding="80px"
    >
      <div className="container">
        <div className="row">
          <ThankYouWrapper className="col-md-8 offset-md-2">
            <p style={{ fontWeight: 800, marginTop: "20px"}}>
              Thanks for reserving your Uno.
            </p>
            <p>
              We'll be in touch when Uno launches in your area
              so that you can complete your order.
            </p>
            <p>
            Invite your friends to reserve their Uno by sharing this link:<br/>
            <span style={{color: "#003dcf"}}>
             www.introducing.uno/reserve/
            </span>
            </p>
          <p>
            Follow us:
          </p>
          <div className="socialIconsContainer"> 
          <a href="https://www.facebook.com/unocup/" target="blank"><Image src={socialFB} alt="" className="socialIcons" style={{  display: "inline", height: "50px"}}/></a>
          <a href="https://www.instagram.com/introducing.uno/" target="blank"><Image src={socialIG} alt="" className="socialIcons" style={{  display: "inline", paddingLeft: "20px", height: "50px"}}/></a>
          <a href="https://twitter.com/introducingUno/" target="blank"><Image src={socialTW} alt="" className="socialIcons" style={{  display: "inline", paddingLeft: "20px", height: "50px",}}/></a>
          </div>
          </ThankYouWrapper>
        </div>
      </div>
    </UnoSection>
  </Layout>
);

export default ThankYouPage;
